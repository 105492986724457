/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */

/*Style fra https://stackblitz.com/edit/angular-mat-table-inline-editing?file=app%2Ftable-editing-example.css
*/

/*override font in this class*/



.minimerPaddingAv {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-top-width: 0 !important;
  border-bottom-width: 0 !important;
  padding-left: 5px;
  padding-right: 5px;
}

.hvit{
    color: #fff;
}

/*
.ingenThPadding{
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;
}*/


.small-width-dialog .mat-dialog-container {
  max-width: 50vw !important;
}

.small-width-dialog-two {
  max-width: 80vw !important;
}



.example-container {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  font-family: Arial, Helvetica, sans-serif;
}

.bold-font {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: black;
  font-size: 120%;
}

.bold-font-white {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #FFFFFF;
}

.bold-font-white-plan {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: white;
}

.bold-font-red {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #FFFFFF;
  background-color: orangered;
}

.margininnputt {
  margin-left: 62px !important;
}

.mat-button {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
}
/*
.mat-form-field--inline {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.mat-form-field--inline .mat-form-field {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}


  .mat-form-field--inline .mat-form-field:nth-child(1) {
    margin-right: 120px !important;
  }
    */


.valgvelger {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}


.mat-form-field-infix {
  width: auto !important;
}

.margin-venstre{
    margin-right: 13px !important;
}


.mat-form-field {
  font-size: 14px;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  margin-right: 2em;
  padding: 0px !important;
}


/*
.mat-form-field {
  font-size: 14px;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  margin-right: 2em;
}*/

.mat-table {
  overflow: auto;
  max-height: 750px;
  font-family: Arial, Helvetica, sans-serif;
}
/*Style slutt*/

a {
  color: #0366d6;

}

code {
  color: #e01a76;
}

.rod {
    color: #dc1212
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}



.example-form {
  min-width: 150px;
  max-width: 500px;
  width: 100%;
}



.tbody {
  display: table-row-group;
  
}


i:hover {
  cursor: pointer;
}

input.ng-invalid.ng-touched, select.ng-invalid.ng-touched {
  border-color: #dc3545;
}


.elementToFadeInAndOut {
  -webkit-animation: fadeinout 2s linear forwards;
  animation: fadeinout 2s linear forwards;
  opacity: 0;
}

@-webkit-keyframes fadeinout {
  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  50% {
    opacity: 1;
  }
}


/*Sentrere spinner*/
.mat-spinner {
  margin-top: 100px !important;
  width: 800px;
  margin: 0 auto;
}
